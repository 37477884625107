
import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Offers from '../components/Offers'
import VideoGallery from '../components/Videos'

export default function Services() {
  return (
    <div>
      <p>Our Services are top notch...</p>
      <Navbar/>
      <Offers/>
      <VideoGallery/>
      <Footer/>
    </div>
  )
}
