import React from 'react';

const Offers = () => {
  return (
    <div className="services-container">
      <p className="services-subtitle">UNITING THE DISCIPLINES OF DESIGN & CONSTRUCTION</p>
      <h2 className="services-title">Home remodeling contractors with a Proven Process</h2>
      
      <div className="services-grid">
        <div className="service-item">
          <div className="service-icon">🏢</div>
          <h3 className="service-title">FEASIBILITY STUDY</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🔧</div>
          <h3 className="service-title">PRE-CONSTRUCTION</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🧱</div>
          <h3 className="service-title">CONSTRUCTION</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🏡</div>
          <h3 className="service-title">WARRANTY & PROMISE</h3>
        </div>
      </div>
    </div>
  );
};

export default Offers;
